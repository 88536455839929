import { graphql, useStaticQuery } from "gatsby";

import React from "react";

const Product = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { slug: { eq: "contact" } } }) {
        edges {
          node {
            id
            html
          }
        }
      }
    }
  `);
  const {
    allMarkdownRemark: { edges },
  } = data;
  return (
    <div
      id="contact"
      className="w-screen h-auto container grid-12 sm:min-h-screen bg-white py-4 md:py-6 section--contact"
    >
      <div className="col-span-12 flex items-center justify-center">
        <div
          className="font-mono text-black text-sm"
          dangerouslySetInnerHTML={{
            __html: data.allMarkdownRemark.edges[0].node.html,
          }}
        />
      </div>
    </div>
  );
};

export default Product;
