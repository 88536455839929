import React from "react";
import WorksIntro from "../works/intro";
import WorksItems from "../works/items";

const Works = () => {
  return (
    <div id="works">
      <WorksIntro />
      <WorksItems />
    </div>
  );
};

export default Works;
