import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { graphql, useStaticQuery } from "gatsby";

import AnchorLink from "react-anchor-link-smooth-scroll";
import React from "react";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {}
          frontmatter: { slug: { eq: "footer" } }
        }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              slug
            }
          }
        }
      }
    }
  `);

  return (
    <Accordion>
      <AccordionItem>
        <AccordionItemHeading className="w-full bg-black font-mono text-white flex justify-center py-2">
          <AccordionItemButton>
            <AnchorLink href="#footer">Datenschutz / Impressum</AnchorLink>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div id="footer" className="container grid-12 font-mono py-4">
            <div className="col-span-12 md:col-span-10 md:col-start-2">
              <div
                className="col-span-12 md:col-span-10 md:col-start-2 font-mono"
                dangerouslySetInnerHTML={{
                  __html: data.allMarkdownRemark.edges[0].node.html,
                }}
              />
            </div>
          </div>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Footer;
