import { graphql, useStaticQuery } from "gatsby";

import Player from "../player";
import React from "react";

const Works = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/works/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              collaborations
              year
              slug
              thumbnail
              audio
            }
          }
        }
      }
    }
  `);
  const {
    allMarkdownRemark: { edges },
  } = data;

  const textBlock = (title, body) => (
    <div className="col-span-10 md:col-span-5">
      <h3
        dangerouslySetInnerHTML={{
          __html: title,
        }}
        className="font-bold mb-3"
      />
      <span dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  );

  const imageBlock = (audioSrc, thumbnailSrc) => (
    <div className="col-span-10 md:col-span-5">
      <div className="relative w-full" style={{ paddingTop: "66.6%" }}>
        <Player audioSrc={audioSrc} />
        {thumbnailSrc !== null && (
          <img
            src={thumbnailSrc.replace("static", "media")}
            className="w-full h-full absolute top-0 left-0 right-0 bottom-0 object-cover image-bw"
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="bg-white py-4 md:py-6">
      <div className="container grid-12">
        <div className="hidden md:grid col-span-12 md:col-span-10 md:col-start-2 font-mono grid-cols-10 gap-3 gap-y-4 ">
          {edges.map(({ node: { frontmatter, html } }, index) => (
            <>
              {index % 2 === 0
                ? textBlock(frontmatter.slug, html)
                : imageBlock(frontmatter.audio, frontmatter.thumbnail)}
              {index % 2 === 0
                ? imageBlock(frontmatter.audio, frontmatter.thumbnail)
                : textBlock(frontmatter.slug, html)}
            </>
          ))}
        </div>
        <div className="grid md:hidden col-span-12 md:col-span-10 md:col-start-2 font-mono grid-cols-10 gap-3 ">
          {edges.map(({ node: { frontmatter, html } }) => (
            <>
              {imageBlock(frontmatter.audio, frontmatter.thumbnail)}
              {textBlock(frontmatter.slug, html)}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Works;
