import React from "react";

export default () => (
  <svg width="144" height="143" viewBox="0 0 144 143" fill={"none"}>
    <circle
      cx="71.7578"
      cy="71.4536"
      r="69.3539"
      stroke="#FF007F"
      stroke-width="4"
    />
    <rect x="51" y="28" width="4" height="86" fill="#FF007F" />
    <rect x="89.7061" y="28" width="4" height="86" fill="#FF007F" />
  </svg>
);
