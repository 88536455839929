import React from "react";
import ReactHelmet from "react-helmet";

const Helmet = () => (
  <ReactHelmet>
    <meta charSet="utf-8" />
    <link rel="canonical" href="https://www.studiolaerm.net" />
    <title>Studio Lärm</title>
    <meta name="title" content="Studio Lärm" />
    <meta
      name="description"
      content=""
    />
    <meta
      name="keywords"
      content=""
    />
    <meta
      property="og:title"
      content=""
    />
    <meta property="og:site_name" content="Studio Lärm" />
    <meta property="og:url" content="https://www.studiolaerm.net" />
    <meta
      property="og:description"
      content=""
    />
    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content="https://www.studiolaerm.net/images/og-studiolaerm.jpg"
    />
  </ReactHelmet>
);

export default Helmet;
