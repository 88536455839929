import { graphql, useStaticQuery } from "gatsby";

import React from "react";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: {}, frontmatter: { slug: { eq: "about" } } }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              slug
            }
          }
        }
      }
    }
  `);
  const {
    allMarkdownRemark: { edges },
  } = data;
  return (
    <div
      className="w-screen h-auto sm:min-h-screen bg-pink text-white py-4 md:py-6 section--about flex items-center"
      id="about"
    >
      <div className="container grid-12">
        <div className="col-span-12 text-center mb-4">
          <h2
            dangerouslySetInnerHTML={{
              __html: data.allMarkdownRemark.edges[0].node.frontmatter.title,
            }}
            className="text-sm md:text-md font-mono"
          />
        </div>
        <div
          className="col-span-12 md:col-span-10 md:col-start-2 font-mono"
          dangerouslySetInnerHTML={{
            __html: data.allMarkdownRemark.edges[0].node.html,
          }}
        />
      </div>
    </div>
  );
};

export default About;
