import React, { useRef, useState } from "react";

import Pause from "../assets/icons/pause";
import Play from "../assets/icons/play";

const Container = ({ audioSrc }) => {
  const ref = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    setPlaying(!playing);
    playing === true && ref.current.pause();
    playing === false && ref.current.play();
  };

  return (
    <>
      {audioSrc !== null && audioSrc !== undefined && (
        <>
          <audio
            controls
            src={audioSrc.replace("static", "media")}
            ref={ref}
            className="hidden"
            preload={"none"}
            onCanPlay={() => setLoading(false)}
          />
          <button
            onClick={() => handleClick()}
            className="w-full h-full absolute top-0 left-0 z-20 flex items-center justify-center"
            style={{ outline: 0 }}
          >
            {playing === false && <Play />}
            {playing === true && loading === true && <span className="font-mono text-pink text-sm">lade ...</span>}
            {playing === true && loading === false && <Pause />}
          </button>
        </>
      )}
    </>
  );
};

export default Container;
