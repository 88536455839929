import About from "../components/sections/01-about";
import Contact from "../components/sections/04-contact";
import Footer from "../components/sections/05-footer";
import Helmet from "../components/helmet";
import Landing from "../components/sections/00-landing";
import Layout from "../components/layout";
import React from "react";
import Services from "../components/sections/03-services";
import Works from "../components/sections/02-works";

const IndexPage = () => {
  const menuItems = [
    { name: "Über uns", anchor: "about" },
    { name: "Projekte", anchor: "works" },
    { name: "Im Studio", anchor: "services" },
    { name: "Kontakt", anchor: "contact" },
  ];
  return (
    <>
      <Helmet />
      <Layout menuItems={menuItems}>
        <Landing />
        <About />
        <Works />
        <Services />
        <Contact />
        <Footer />
      </Layout>
    </>
  );
};

export default IndexPage;
