import Div100vh from "react-div-100vh";
import React from "react";

const Header = () => (
  <Div100vh>
    <div className="w-screen h-full">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="h-full w-full object-cover"
        style={{ filter: "grayscale(1) contrast(2)" }}
      >
        <source src={`video/laerm-landing.mp4`} type="video/mp4" />
      </video>

      <div className="absolute w-full h-full top-0 left-0 flex items-center justify-center">
        <h1 className="font-mono font-bold text-2xl md:text-3xl text-white">
          lärm
        </h1>
      </div>
    </div>
  </Div100vh>
);

export default Header;
