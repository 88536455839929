import React from "react";

export default () => (
  <svg width="144" height="143" viewBox="0 0 144 143" fill={"none"}>
    <circle
      cx="71.7578"
      cy="71.4536"
      r="69.3539"
      stroke="#FF007F"
      stroke-width="4"
    />
    <path
      d="M55.5249 102.66V40.248L97.9904 71.1322L55.5249 102.66Z"
      fill="#FF007F"
    />
  </svg>
);
